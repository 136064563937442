body, html {
  font-family: 'Oswald', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

@font-face {
  font-family: "VIDEOPHREAK";
  src: local("VIDEOPHREAK"),
   url("./fonts/VIDEOPHREAK.ttf") format("truetype");
  font-weight: bold;
  }
  

h1 {
  color: white;
}

h4 {
  text-decoration: underline;
  
}

p {
  padding: 1%;
}

a {
  display: inline-block;
  text-align: center;
}

.fb {
  color: azure;

}

i {
  
  padding: 5px 15px;
  
}
.fab fa-html5 fa-7x{
  animation-name: pulse;
  animation-duration: 3s;
}

span {
  font-size: medium;
}

.description2 {
  color: #002A56;
  margin: 0 5%;
  font-size: 1.4rem;
  text-align: left;
  
  
}

.description {
  color: azure;
  margin: 0 5%;
  font-size: 1.4rem;
  text-align: left;
}

a {
  margin: 2% 0;
  color: azure;
  text-decoration: none;
}

input {
  height: 55px;
  border-radius: 7px;
  margin: 2%;
  width: 80%;
  font-size: x-large;
}
.nme {
  margin-top: 7%;
}

textarea {
  height: 150px;
  border-radius: 7px;
  margin: 2%;
  width: 80%;
  font-size: 24px;
}

.link {
  color: azure;
  width: 25%;
  background-color: transparent;
  cursor: pointer;
}

.btn {
  height: 55px;
  border-radius: 7px;
  margin: 2%;
  width: 25%;
  font-size: x-large;
  color: #002A56;
  background-color: clear
}

.nav-wrap {
  display: flex;
  justify-content: space-between;
  background-color: #002A56;
  width: 100%;
  height: 50px;
  z-index: 9;
  border-bottom: 1px solid goldenrod;
  position: sticky;
  top: 0;

}

::placeholder {
  font-size: x-large;
}

.selfie {
  width: 300px;
  
  border-radius: 12px;
  align-items: center;
}

.logo {
  background-image: url('./assets/001.jpeg'), linear-gradient(rgba(0, 0, 151, 0.81),rgba(0, 0, 151, 0.60));
  background-blend-mode: overlay;
  height: 95vh;
  max-width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center ;
  background-size: cover; 
  z-index: 1;
  
}

.sd {
  margin-bottom: 5%;
  width: 100%;
  height: 100vh;
}

.projects {
  border-top: 1px solid azure;
}


.hamburger-react {
  margin-left: 2%;
  z-index: 99;
  position: absolute;
  
  
}

#skills {
  background-color: #002A56;
}

.icons:hover {
  background-color: azure;
  color: #002A56;
  background-size: contain;
}

.icon-wrap {
  margin-right: 1%;
  width: 300px;
}

.image-text {
  position: absolute;
  
}

.back-to-top {
  color: #002A56;

}


/* ID  */

#contact {
  text-align: center;
  height: 50px;
}

#form {
  background-color: #002A56;
}

#bubble {
  justify-content: center;
  width: 120px;
  height: 80px;
  background:  #002A56;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
#bubble:before {
  justify-content: center;
  content: "";
  position: absolute;
  right: 100%;
  top: 26px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid  #002A56;
  border-bottom: 13px solid transparent;
}

/* #bWrap {
  padding: 10%;
  height: 250px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

#img-h3 {
  margin: 0;
}

#bio {
  
  width: 65%;
  text-align: center;
  margin: 0;
  font-size: 1.1rem;
  text-shadow: none;
  color:#002A56; 
}

#image-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#two {
  text-decoration: underline;
  color: #002A56;
}

@media screen and (max-width: 501px){
  .port-img {
    width: 400px;
    height: 400px;
  }
   
}